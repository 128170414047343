{
  "mutators": [
    "随机",
    "龙卷风暴",
    "强行征用",
    "暗无天日",
    "时空扭曲",
    "鼓舞人心",
    "时空力场",
    "晶矿护盾",
    "焦土政策",
    "复仇战士",
    "闪避机动",
    "岩浆爆发",
    "激光钻机",
    "自毁程序",
    "异形寄生",
    "扫雷专家",
    "超远视距",
    "短视症",
    "默哀",
    "来去无踪",
    "相互摧毁",
    "震荡攻击",
    "强磁雷场",
    "减伤屏障",
    "速度狂魔",
    "丧尸大战",
    "轨道轰炸",
    "净化光束",
    "暴风雪",
    "无边恐惧",
    "光子过载",
    "致命勾引",
    "核弹打击",
    "生命汲取",
    "灵能爆表",
    "双重压力",
    "坚强意志",
    "行尸走肉",
    "黑死病",
    "给我死吧",
    "伤害散射",
    "力量蜕变",
    "进攻部署",
    "虚空重生者",
    "异形同化",
    "风暴英雄",
    "虚空裂隙",
    "小捞油水",
    "拿钱说话",
    "极性不定"
  ],
  "maps": [
    "熔火危机",
    "虚空撕裂",
    "虚空降临",
    "往日神庙",
    "聚铁成兵",
    "天界封锁",
    "升格之链",
    "克哈裂隙",
    "净网行动",
    "机会渺茫",
    "黑暗杀星",
    "湮灭快车",
    "营救矿工",
    "死亡摇篮",
    "亡者之夜"
  ],
  "mapDescription": [
    "埃蒙引发的火山地壳运动正在摧毁维丽蒂亚主星。采集重启环境稳定装置所需要的水晶，挽救整个星球于毁灭的边缘。",
    "埃蒙的军队已经在查尔星球上围困了“重锤”军士巴玛·科瓦尔斯基的要塞。帮助她击败虚空撕裂者，扭转战争形势，还要时刻留意莫比斯大天使——击败这种单位可以获得大量赏金。",
    "因为埃蒙试图使用穿梭机在星灵的时空航道进行军队运输，卡亚迪尔再度成为了冲突上演的舞台。泰伦科学家卡特赖特和他的队伍需要你的帮助来阻挠埃蒙的计划，同时在他们调查三座萨尔纳加神庙时保护他们的大力神运输机。",
    "埃蒙已经将魔爪伸向了萨古拉斯的萨尔纳加神庙，高阶保护者洛哈娜需要帮助来摧毁他的阴谋。必须消灭堕落者的军队，但要小心，神庙的能量并不稳定，当泽尼斯巨石超载时要及时摧毁它们。",
    "莫比斯军团的研究员一直在进行混合体实验，企图创造出更具破坏力的的怪物。协助戴维斯将军，让她驾驶莫比斯军团尚未完工的机甲“巴利俄斯”来终结这一切，消灭混合体。",
    "埃蒙试图通过控制天锁并使其能量超载的方式毁灭乌尔纳。你必须激活全部五个天锁并阻止埃蒙的军队占领天锁。保持警惕，因为埃蒙的腐化能量能够使萨尔纳加的建筑反过来攻击你。",
    "埃蒙企图重掌塔达林控制权。在拉克希尔仪式中帮助升格第一人吉娜拉，助其击败埃蒙的勇士。",
    "泰伦帝国的领地正在遭受攻击，埃蒙部署了虚空碎片，一旦激活就会造成无法挽回的毁灭。协助法拉第下士摧毁虚空碎片，保护奥古斯特格勒免受灭顶之灾，同时，你也需要帮忙抵御试图洗劫城市的凯莫瑞安海盗。",
    "多年前，这个星灵设施用来把伟大的战士转化为智能人格，也就是“净化者”。研究者对一名塔达林囚犯进行测试时，他的智能人格叛变了，损坏并接管了整个设施的功能。现在净化者的守护者奥罗娜正努力夺回控制权。",
    "在阿克图尔斯倒台之前就已困在拜舍尔的艾贡·斯台特曼，如今遭到了埃蒙部队的袭击。保护好他的地嗪采集机器人，在它们工作时别让它们死在敌人手中。",
    "奈拉齐姆侦察员莱拉克发现了一件令人震惊的事情：埃蒙的部队侵入了一座萨尔纳加神庙，并且正在利用其能量唤醒黑暗之神最强大的造物之一。摧毁虚空裂片，阻止他们的计划，否则一切都将毁灭。",
    "莫比斯军团占据了塔桑尼斯星球上的一条旧铁路，用来运输补给和病员。加入臭名昭著的雇佣兵格莱文·希尔的军队，摧毁疾驰的列车，阻止莫比斯的计划。",
    "凯莫瑞安的一处边远采矿移民地爆发了感染体危机。当地的矿工公会会长——德布拉·格林，决心将她的民众撤离到安全地带。同时面对埃蒙和感染体两股敌人，协助矿工顺利撤离。",
    "莫比斯军团将巨型天基武器站对准了帝国的殖民地。帮助斯通把萨尔那加神器运送到目标地点，摧毁武器站，拯救无辜的生命。",
    "帝国的一颗边缘星球爆发了一场蔓延迅猛的感染危机。在其造成更大范围的破坏之前，焚烧掉所有被感染的建筑。"
  ],
  "commanders": [
    "随机",
    "雷诺",
    "凯瑞甘",
    "阿塔尼斯",
    "斯旺",
    "扎加拉",
    "沃拉尊",
    "凯拉克斯",
    "霍纳与汉",
    "阿拉纳克",
    "诺娃",
    "斯科托夫",
    "菲尼克斯",
    "阿巴瑟",
    "泽拉图",
    "蒙斯克",
    "泰凯斯",
    "德哈卡"
  ]
}