import commander0 from "./0.jpg";
import commander1 from "./1.jpg";
import commander10 from "./10.jpg";
import commander11 from "./11.jpg";
import commander12 from "./12.jpg";
import commander13 from "./13.jpg";
import commander14 from "./14.jpg";
import commander15 from "./15.jpg";
import commander16 from "./16.jpg";
import commander17 from "./17.jpg";
import commander2 from "./2.jpg";
import commander3 from "./3.jpg";
import commander4 from "./4.jpg";
import commander5 from "./5.jpg";
import commander6 from "./6.jpg";
import commander7 from "./7.jpg";
import commander8 from "./8.jpg";
import commander9 from "./9.jpg";

export const commanders = [
  commander0,
  commander1,
  commander2,
  commander3,
  commander4,
  commander5,
  commander6,
  commander7,
  commander8,
  commander9,
  commander10,
  commander11,
  commander12,
  commander13,
  commander14,
  commander15,
  commander16,
  commander17,
];
