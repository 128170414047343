import map0 from "./0.jpg";
import map1 from "./1.jpg";
import map2 from "./2.jpg";
import map3 from "./3.jpg";
import map4 from "./4.jpg";
import map5 from "./5.jpg";
import map6 from "./6.jpg";
import map7 from "./7.jpg";
import map8 from "./8.jpg";
import map9 from "./9.jpg";
import map10 from "./10.jpg";
import map11 from "./11.jpg";
import map12 from "./12.jpg";
import map13 from "./13.jpg";
import map14 from "./14.jpg";

export const maps = [
  map0,
  map1,
  map2,
  map3,
  map4,
  map5,
  map6,
  map7,
  map8,
  map9,
  map10,
  map11,
  map12,
  map13,
  map14,
];
