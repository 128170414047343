import mutator0 from "./0.jpg";
import mutator1 from "./1.jpg";
import mutator10 from "./10.jpg";
import mutator11 from "./11.jpg";
import mutator12 from "./12.jpg";
import mutator13 from "./13.jpg";
import mutator14 from "./14.jpg";
import mutator15 from "./15.jpg";
import mutator16 from "./16.jpg";
import mutator17 from "./17.jpg";
import mutator18 from "./18.jpg";
import mutator19 from "./19.jpg";
import mutator2 from "./2.jpg";
import mutator20 from "./20.jpg";
import mutator21 from "./21.jpg";
import mutator22 from "./22.jpg";
import mutator23 from "./23.jpg";
import mutator24 from "./24.jpg";
import mutator25 from "./25.jpg";
import mutator26 from "./26.jpg";
import mutator27 from "./27.jpg";
import mutator28 from "./28.jpg";
import mutator29 from "./29.jpg";
import mutator3 from "./3.jpg";
import mutator30 from "./30.jpg";
import mutator31 from "./31.jpg";
import mutator32 from "./32.jpg";
import mutator33 from "./33.jpg";
import mutator34 from "./34.jpg";
import mutator35 from "./35.jpg";
import mutator36 from "./36.jpg";
import mutator37 from "./37.jpg";
import mutator38 from "./38.jpg";
import mutator39 from "./39.jpg";
import mutator4 from "./4.jpg";
import mutator40 from "./40.jpg";
import mutator41 from "./41.jpg";
import mutator42 from "./42.jpg";
import mutator43 from "./43.jpg";
import mutator44 from "./44.jpg";
import mutator45 from "./45.jpg";
import mutator46 from "./46.jpg";
import mutator47 from "./47.jpg";
import mutator48 from "./48.jpg";
import mutator49 from "./49.jpg";
import mutator5 from "./5.jpg";
import mutator6 from "./6.jpg";
import mutator7 from "./7.jpg";
import mutator8 from "./8.jpg";
import mutator9 from "./9.jpg";

export const mutators = [
  mutator0,
  mutator1,
  mutator2,
  mutator3,
  mutator4,
  mutator5,
  mutator6,
  mutator7,
  mutator8,
  mutator9,
  mutator10,
  mutator11,
  mutator12,
  mutator13,
  mutator14,
  mutator15,
  mutator16,
  mutator17,
  mutator18,
  mutator19,
  mutator20,
  mutator21,
  mutator22,
  mutator23,
  mutator24,
  mutator25,
  mutator26,
  mutator27,
  mutator28,
  mutator29,
  mutator30,
  mutator31,
  mutator32,
  mutator33,
  mutator34,
  mutator35,
  mutator36,
  mutator37,
  mutator38,
  mutator39,
  mutator40,
  mutator41,
  mutator42,
  mutator43,
  mutator44,
  mutator45,
  mutator46,
  mutator47,
  mutator48,
  mutator49,
];
